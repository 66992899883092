import { LogoLoader } from "@/components";
import { ServerError } from "@/components/ServerError/ServerError";
import { useStores } from "@/stores";
import "@/styles/index.css";
import "@/styles/whatsapp.css";
import { AddressesProvider, AdpageBody, CartProvider, GtmScript, LastListerProvider, LayoutErrorBoundary, LocationProvider, MeProvider, MembershipProvider, PendingProvider, PrefetchProvider, StatsigProvider, TrustedShopScript, WhatsappScript, } from "@jog/react-components";
import { PrismicPreview } from "@prismicio/next";
import { Analytics } from "@vercel/analytics/react";
import { observer } from "mobx-react";
import { SessionProvider } from "next-auth/react";
import Script from "next/script";
import { StoresProvider } from "stores/StoresProvider";
const CartProviderWrapper = observer(function CartProviderWrapper({ children }) {
    const { cartStore } = useStores();
    return <CartProvider cartStore={cartStore}>{children}</CartProvider>;
});
function AppContainer({ loader, children }) {
    return (<MeProvider>
            <AddressesProvider>
                <MembershipProvider>
                    <PrefetchProvider data={loader}>
                        <TrustedShopScript />
                        {process.env.NEXT_PUBLIC_SQUEEZELY_ENABLE && (<Script id="squeezely-worker" src="/squeezely-worker.js"/>)}
                        {process.env.NEXT_PUBLIC_WHATSAPP_ENABLE && <WhatsappScript />}
                        <LastListerProvider>
                            <StoresProvider>
                                <GtmScript />
                                <AdpageBody>
                                    <LocationProvider>
                                        <PrismicPreview updatePreviewURL="/api/preview" exitPreviewURL="/api/exit-preview" repositoryName={process.env.NEXT_PUBLIC_PRISMIC_REPO}>
                                            <PendingProvider Pending={LogoLoader}>
                                                <CartProviderWrapper>
                                                    <StatsigProvider>{children}</StatsigProvider>
                                                </CartProviderWrapper>
                                            </PendingProvider>
                                        </PrismicPreview>
                                    </LocationProvider>
                                </AdpageBody>
                                <Analytics />
                            </StoresProvider>
                        </LastListerProvider>
                    </PrefetchProvider>
                </MembershipProvider>
            </AddressesProvider>
        </MeProvider>);
}
export default function App(props) {
    if (props.pageProps.loader) {
        return (<LayoutErrorBoundary Fallback={() => <ServerError from="_app"/>}>
                <SessionProvider>
                    <AppContainer loader={props.pageProps.loader}>
                        <props.Component {...props.pageProps}/>
                    </AppContainer>
                </SessionProvider>
            </LayoutErrorBoundary>);
    }
    return <ServerError from="_app"/>;
}
