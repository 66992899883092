import { CheckMark } from "@/components/Icon/CheckMark";
import { useI18n } from "@jog/react-components";
export const CheckMarkItem = ({ text }) => (<div className="flex items-start">
        <CheckMark width={16} height={16} strokeWidth={4} className="my-1 mr-2 shrink-0 text-green-main"/>
        <span className="leading-6">{text}</span>
    </div>);
export const CheckMarks = ({ store: { hasKids, hasTailor, hasCoffee, hasDHLServicePoint }, className, }) => {
    const { $t } = useI18n();
    return (<div className={className}>
            <CheckMarkItem text={$t("Women & men collectie")}/>
            {hasKids && <CheckMarkItem text={$t("Boys & girls collection")}/>}
            {hasTailor && <CheckMarkItem text={$t("Jeans workshop: have your jeans entertained for free!")}/>}
            {hasCoffee && <CheckMarkItem text={$t("Coffee corner")}/>}
            {hasDHLServicePoint && <CheckMarkItem text={$t("DHL Service Point")}/>}
        </div>);
};
